@import "src/styles/_variables-mixins.scss";
.container {
  position: fixed;
  z-index: 598;
  width: 100vw;
  top: 0;
  left: 0;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.723);
  overflow: auto;

  & .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@import "src/styles/_variables-mixins.scss";
.register {
  display: flex;
  flex-direction: column;
  align-self: center;

  width: 100%;
  margin: auto;

  :global(.title) {
    text-align: center;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 24px;

    width: 100%;
  }

  &__recaptcha {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & p {
      font-size: 14px;
      font-weight: 500;
      color: #ce2e2e;
    }
  }

  &__phone-error {
    display: flex;
    align-items: center;
    gap: 8px;

    margin-top: 10px;

    & p {
      font-size: 14px;
      font-weight: 500;
      color: #ce2e2e;
    }
  }

  .phoneInput {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 44px;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid var(--mobility-color-dark-100);
    color: var(--mobility-color-dark-950);
    font-family: var(--font-outfit);
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
    & input {
      border: none;
      &:focus {
        outline: none;
      }
    }

    &:hover {
      outline: none;
      border: 1px solid var(--mobility-color-orange-300);
    }

    &__label {
      width: 100%;
      line-height: 44px;
      color: var(--mobility-color-dark-950);
      font-weight: 500;
      font-size: 0.875rem;
    }
  }
  .error {
    @extend .phoneInput;
    border: 1px solid #ce2e2e !important;
  }
}

.error {
  @extend .phoneInput;
  border: 1px solid #ce2e2e !important;
}
